.education {
  min-height: fit-content;
}

.education-body {
  display: grid;
  grid-template-columns: 35% 65%;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: hsla(117, 56%, 49%, 0.702);
}

.education-description {
  width: 100%;
  flex: 0.65;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 7%;
}

.education-description>h1 {
  font-size: 2.5rem;
  font-family: var(--primaryFont);
  margin-bottom: 2rem;
}

.education-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  min-height: 140px;
  height: auto;
  padding: 1.5rem;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  transition: background-color 200ms ease-in-out;
}

.educard-img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.educard-img>img {
  width: 40px;
}

.education-details {
  margin-left: 0.6rem;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.education-details>h6 {
  font-family: var(--primaryFont);
  font-size: 0.85rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.education-details>h4 {
  font-family: var(--primaryFont);
  font-size: 1.225rem;
  font-weight: 600;
}

.education-details>h5 {
  font-family: var(--primaryFont);
  font-size: 1.15rem;
  font-weight: 600;
}

.education-details > h2 {
  font-family: var(--primaryFont);
  font-size: 1.1rem;
  margin-top: 0.4rem;
  word-break: break-word;
}

.education-image {
  box-sizing: border-box;
  padding: 1rem;
  flex: 0.35;
  margin-top: 1rem;
  pointer-events: none;
}

.education-image>img {
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1380px) {}

@media screen and (max-width: 992px) {
  .education-body {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .education {
    min-height: 100%;
  }

  /* .education-image {
    display: none;
  } */

  .education-description {
    flex: 1;
    margin: auto;
  }

  .education-description>h1 {
    font-size: 3.2rem;
  }

  .education-card {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .education-description>h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    align-self: center;
  }

  .education-description {
    padding: 1rem;
  }

  .education-card {
    padding: 1rem;
    margin-bottom: 1.5rem;
    min-height: 130px;
    height: auto;
    width: 100%;
  }

  .educard-img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
  }

  .educard-img>img {
    width: 30px;
  }

  .education-details>h6 {
    font-size: 0.8rem;
    margin-bottom: 0.4rem;
  }

  .education-details>h3 {
    font-size: 0.95rem;
    line-height: 120%;
    margin-bottom: 0.3rem;
  }

  .education-details>h2 {
    font-size: 1rem;
    line-height: 120%;
    margin-top: 0.3rem;
  }

  .education-details>h4 {
    font-size: 0.95rem;
    line-height: 120%;
  }

  .education-details>h5 {
    font-size: 0.9rem;
    line-height: 120%;
  }
}

@media screen and (max-width: 600px) {
  .education-description>h1 {
    font-size: 2.3rem;
  }
  
  .education-details>h3 {
    font-size: 0.9rem;
    line-height: 115%;
  }
  
  .education-details>h2 {
    font-size: 0.95rem;
    line-height: 115%;
  }
}

@media screen and (max-width: 480px) {
  .education-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem 0.8rem;
  }
  
  .education-details {
    margin-left: 0;
    margin-top: 0.8rem;
  }
  
  .education-details > h2,
  .education-details > h3,
  .education-details > h6 {
    text-align: center;
  }
  
  .education-details>h3 {
    font-size: 0.85rem;
    margin-bottom: 0.2rem;
  }
  
  .education-details>h2 {
    font-size: 0.9rem;
  }
  
  .education-details>h6 {
    font-size: 0.75rem;
  }
}